let ReferralStats = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;




    /**
     *
     * @param startTime
     * @param endTime
     * @param hospitalId
     * @param depId
     * @param pageIndex
     * @param state
     * @param keyWord
     * @param success
     * @param error
     */
    this.ReferralList = function (startTime, endTime, depId, pageIndex, state, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalReferralStatistics/ReferralStatistics';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (depId) {
            url += `/${depId}`;
        }
        else {
            url += '/0';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        else {
            url += '/1';
        }
        if (state) {
            url += `/${state}`;
        }
        else {
            url += '/999';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.ChildrenReferralList = function (startTime, endTime, hospitalId, depId, pageIndex, state, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalReferralStatistics/ChildrenReferralStatistics';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (hospitalId) {
            url += `/${hospitalId}`;
        }
        else {
            url += '/0';
        }
        if (depId) {
            url += `/${depId}`;
        }
        else {
            url += '/0';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        else {
            url += '/1';
        }
        if (state) {
            url += `/${state}`;
        }
        else {
            url += '/999';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     *
     * @param startTime
     * @param endTime
     * @param hospitalId
     * @param depId
     * @param pageIndex
     * @param state
     * @param keyWord
     * @param success
     * @param error
     */
    this.ReceptionList = function (startTime, endTime, depId, pageIndex, state, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalReferralStatistics/ReceptionStatistics';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (depId) {
            url += `/${depId}`;
        }
        else {
            url += '/0';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        else {
            url += '/1';
        }
        if (state) {
            url += `/${state}`;
        }
        else {
            url += '/999';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.ChildrenReceptionList = function (startTime, endTime, hospitalId, depId, pageIndex, state, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalReferralStatistics/ChildrenReceptionStatistics';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (hospitalId) {
            url += `/${hospitalId}`;
        }
        else {
            url += '/0';
        }
        if (depId) {
            url += `/${depId}`;
        }
        else {
            url += '/0';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        else {
            url += '/1';
        }
        if (state) {
            url += `/${state}`;
        }
        else {
            url += '/999';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


}

export { ReferralStats }
